import styled from "styled-components"
import { getGutterAsPixels, getMediaQuery, getUnitAsPixels, Size } from "../../../theme/layout"
import { getTypography, Type } from "../../../theme/typography"
import { LinkInternal } from "../../core/link"
import { VideoThumbnail } from "../../core/videoThumbnail"

export const Thumbnail = styled(VideoThumbnail)`
  
`

export const Heading = styled.p<{hover:boolean,selected:boolean}>`
  ${getTypography(Type.LABEL_1)}
  color: ${({ theme, hover, selected }) => hover || selected ? theme.secondary.hex: theme.primary.hex};
  transition: color 0.3s;
  text-decoration: none;
  grid-column: 4 / span 5;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 5 / span 8;
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-inline-end: ${getUnitAsPixels({multiplier:2})};
  }
`

export const ThumbnailContainer = styled.div<{hover:boolean}>`
  position: relative;
  display: block;
  grid-column: 1 / span 3;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 4;
  }

  ${getMediaQuery(Size.LARGE)}{
    width: ${getUnitAsPixels({multiplier:18})};
    flex: 0 0 ${getUnitAsPixels({multiplier:18})};
    margin-inline-end: ${getUnitAsPixels({multiplier:2})};
  }

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    transition: opacity 0.3s;
    opacity: ${({hover}) => hover ? 1 : 0};
  }
`

export const Container = styled(LinkInternal)` 
  display: grid;
  grid-template-columns: repeat(8, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(Size.SMALL)};
  grid-template-rows: max-content;
  text-decoration: none;
  margin-bottom: ${getUnitAsPixels({multiplier:2})};

  &:last-child{
    margin-bottom: 0;
  }

  ${getMediaQuery(Size.MEDIUM)}{
    grid-template-columns: repeat(12, [col-start] 1fr);
  }

  ${getMediaQuery(Size.LARGE)}{
    display: flex;
    grid-template-columns: none;
    column-gap: unset;
    grid-template-rows: none;
  }

  
`

export const Badge = styled.span<{rtl:boolean}>`
  ${getTypography(Type.LABEL_3)}
  background-color: ${({ theme }) => theme.primary.hex};
  color: ${({ theme }) => theme.background.hex};
  position: absolute;
  bottom: 0;
  ${({ rtl }) => rtl ? `right: 0;` : `left: 0;`}
  padding: ${getUnitAsPixels()} ${getUnitAsPixels({multiplier:1.5})};

  > svg{
    fill: ${({theme}) => theme.background.hex};
    width: 9px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
`

export const Duration = styled.span`
  margin-inline-start: ${getUnitAsPixels()};
`