import styled from 'styled-components';
import { CONTAINER, getLayout, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, Layout, Size, VerticalSpaceVariant } from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';
import { ShareButtons } from '../../../core/shareButtons';
import { VideoPlayer } from '../../../core/videoPlayer';

export const MediaContainer = styled.div`
  ${CONTAINER}
`;

export const MainContainer = styled.div`
  ${CONTAINER}
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`;

export const MainContent = styled.div`
  ${getLayout(Layout.GRID)}
`;

export const Media = styled(VideoPlayer)`
  ${getLayout(Layout.BLOCK)}
`;

export const Description = styled.div`
  ${getTypography(Type.BODY_1)}
  margin-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }
`;

export const Introduction = styled.div`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 1 / span 8;
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 1 / span 7;
  }
`;

export const Share = styled(ShareButtons)`
  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getSpacerAsPixels({multiplier:1})};
    border-top: 1px solid ${({ theme }) => theme.primary.hex};
  }
`

export const RelatedContainer = styled.div`
  grid-column: 1 / span 8;
  padding-top: ${getSpacerAsPixels({multiplier:1})};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }
  
  ${getMediaQuery(Size.LARGE)}{
    grid-column: 9 / span 4;
    padding-top: 0;
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 10 / span 3;
  }
`;

export const RelatedContent = styled.div`
  ${getMediaQuery(Size.LARGE)}{
    max-height: 525px;
    overflow: auto;
  }
`;

export const RelatedHeading = styled.h2`
  ${getTypography(Type.SECTION_HEADING)}
  border-top: 2px solid ${({theme}) => theme.primary.hex};
  padding-top: ${getUnitAsPixels()};
  padding-bottom: ${getUnitAsPixels({multiplier:4})};

  ${getMediaQuery(Size.LARGE)}{
    ${getTypography(Type.LABEL_1)}
    border-top: none;
    padding-top: 0;
  }
`

export const ShowsContainer = styled.div`
  ${CONTAINER}
  background-color: ${({ theme }) => theme.background.hex};
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`

export const ShowsContent = styled.div`
  ${getLayout(Layout.BLOCK)}
`