import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { getInternalURL } from '../common/link';
import { InlineMediaExternal } from '../components/inline/mediaExternal';
import { CoverTitle } from '../components/cover/title';
import { BodyVideo } from '../components/layout/body/video';
import { shallowRender } from 'preact-render-to-string';

export const query = graphql`
  query TvVideoQuery($id: String!, $locale: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsTvVideo(id: { eq: $id }) {
      id
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      description
      media {
        ...MediaExternal
      }
    }
    show: datoCmsTvShow(videos: {elemMatch: {id: {eq: $id}}}){
      title
      slug
      model {
        ...PageModel
      }
      locale
      videos{
        ...TvVideo
      }
    }
    shows: allDatoCmsTvShow(
      sort: {fields: meta___updatedAt, order: DESC},
      filter: {slug: {ne: null}, locale: {eq: $locale}}
    ){
      nodes{
        model {
          ...PageModel
        }
        locale
        slug
        title
        thumbnail{
          ...TallThumbnail
        }
        videos{
          ...TvVideo
        }
      }
    }
  }
`;

const TvVideo = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const page = prop('page', data);
  const show = prop('show', data);
  const site = prop('site', data);
  const shows = path(['shows','nodes'], data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });
  const pageURL = settings.siteUrl + getInternalURL(page);

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />
      <CoverTitle heading={page.title} breadcrumb={show} />
      <BodyVideo id={page.id} media={page.media} title={page.title} pageURL={pageURL} description={page.description} show={show} shows={shows} />
    </Fragment>
  );
};

export default TvVideo;