import styled from "styled-components";
import { getMediaQuery, getUnitAsPixels, Size } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { LinkInternal } from "../../core/link";

export const Heading = styled.h1`
  ${getTypography(Type.HEADLINE)}
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }
`;
