import React, { useState } from "react";
import { secondsToFormattedString } from "../../../common/date";
import { useIsRTL } from "../../../context/locale";
import PlayIcon from '../../../images/play.svg';
import { Container, Thumbnail, Heading, ThumbnailContainer, Badge, Duration } from "./videoItem.styles";
import { VideoItemProps } from "./videoItem.types";

export const VideoItem = ({selected,video}:VideoItemProps) => {
  const isRTL = useIsRTL();
  const [hover,setHover] = useState(false);
  
  return (
    <Container page={video} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <ThumbnailContainer hover={hover}>
        <Thumbnail {...video.media} />
        <Badge rtl={isRTL}><PlayIcon />{video.duration &&<Duration>{secondsToFormattedString(video.duration)}</Duration>}</Badge>
      </ThumbnailContainer>
      <Heading hover={hover} selected={selected}>{video.title}</Heading>
    </Container>
  )
}