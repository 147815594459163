import React from 'react';
import { Layout } from '../../../theme/layout';
import { CoverContainer } from '../container';
import { CoverMeta } from '../meta';
import { Heading} from './title.styles';
import { CoverTitleProps } from './title.types';

export const CoverTitle = ({heading, breadcrumb, date}:CoverTitleProps) => {
  
  return (
    <CoverContainer layout={Layout.GRID}>
      <CoverMeta breadcrumb={breadcrumb} date={date} />
      <Heading>{heading}</Heading>
    </CoverContainer>
  )
};