import React from "react";
import { ThemeProvider } from "styled-components";
import { useBrandTheme } from "../../../../context/brand";
import { useDictionary } from "../../../../context/dictionary";
import { ShowSlider } from "../../../show/showSlider";
import { VideoItem } from "../../../video/videoItem";
import { Description, Introduction, MainContainer, MainContent, Media, MediaContainer, RelatedContainer, RelatedContent, RelatedHeading, RelatedImage, RelatedItem, RelatedTitle, Share, ShowsContainer, ShowsContent } from "./video.styles";
import { BodyVideoProps, BodyVideoRelatedProps } from "./video.types";

const Related = ({selectedId,videos}:BodyVideoRelatedProps) => {
  const labelTvVideos = useDictionary("labelTvVideos");

  return (
    <RelatedContainer>
      <RelatedHeading>{labelTvVideos}</RelatedHeading>
      <RelatedContent>
        {videos.map(video => (
          <VideoItem video={video} selected={video.id === selectedId} />
        ))}
      </RelatedContent>
    </RelatedContainer>
  )
}

export const BodyVideo = ({id,media,title,pageURL,description,show,shows}:BodyVideoProps) => {
  const lightTheme = useBrandTheme("light");
  const labelTvMoreShows = useDictionary("labelTvMoreShows");
  
  return (
    <>
      <MediaContainer>
        <Media {...media} autoPlay={true} />
      </MediaContainer>
      <MainContainer>
        <MainContent>
          <Introduction>
            {description && <Description>{description}</Description>}
            <Share url={pageURL} title={title} />
          </Introduction>
          {show && <Related selectedId={id} videos={show.videos} />}
        </MainContent>
      </MainContainer>
      <ThemeProvider theme={lightTheme}>
        <ShowsContainer>
          <ShowsContent>
            <ShowSlider items={shows} heading={labelTvMoreShows} />
          </ShowsContent>
        </ShowsContainer>
      </ThemeProvider>
    </>
  )
};